import { default as Box } from "@mui/material/Box";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { db } from "../../components/firebase";
import { Game } from "../../types";
import { gameDate } from "../helper";
import GameScores from "./Scoreboard/GameScores";

export type Props = {
  week: number;
};

export default function GamesMain({ week }: Props) {
  const [currentGames, setCurrentGames] = useState<Game[]>([]);
  const weekFormat = week.toString().padStart(2, "0");
  const weekPath = `weeks/week${weekFormat}/games_sorted/`;

  useEffect(() => {
    const userRef = ref(db, weekPath);
    return onValue(userRef, (snapshot) => {
      if (snapshot.exists()) {
        setCurrentGames(snapshot.val());
      }
    });
  }, [weekPath]);

  const gameStarts = currentGames
    .map((item) => gameDate(item.starts_at))
    .filter((value, index, self) => self.indexOf(value) === index);

  // has to be a better way than this
  const gameArrays: Game[][] = [];
  gameStarts.forEach((startTime: string) => {
    const gameBlock = currentGames.filter(
      (game: Game) => gameDate(game.starts_at) === startTime
    );
    gameArrays.push(gameBlock);
  });

  return (
    <Box id={`gameWeek-${week}`} sx={{ width: "100%", flexGrow: 1 }}>
      {gameArrays.map((games: Game[], index) => (
        <GameScores
          key={`gameWeek-${week}-${index}`}
          header={gameStarts[index]}
          games={games}
        />
      ))}
    </Box>
  );
}
